import React from 'react'
import _ from 'lodash'

import { booleanToText } from 'utils/functions'

import Container from './styles'
import NoDataFoundMessage from '../NoDataFoundMessage'

const IrReceiptValues = ({ receiptData }) => {
  return (
    <React.Fragment>
      <Container>
        <h2>
          <ul>
            <li>Arquivo de Recibo</li>
          </ul>
        </h2>
      </Container>

      {_.isEmpty(receiptData) ? (
        <NoDataFoundMessage message="Arquivo de recibo não enviado" />
          ) : (
            <Container>

              <div className="values-file-container">
                <React.Fragment>
                  <div className="text-container">
                    <p>
                      <strong>{`Recibo válido: ${booleanToText(receiptData.valid)}`}</strong>
                    </p>
                  </div>
                  <div className="table-container" style={{ fontSize: '1.5rem' }}>
                    <table>
                      <thead>
                        <tr>
                          <th>Valores do recibo</th>
                          <th>Valores iguais aos da declaração</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Tipo da declaração:</strong>
                            {' '}
                            <span>{receiptData.type_receipt}</span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <span>{booleanToText(receiptData.type_receipt_valid)}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>CPF:</strong>
                            {' '}
                            <span>{receiptData.cpf}</span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <span>{booleanToText(receiptData.cpf_valid)}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Nome:</strong>
                            {' '}
                            <span>{receiptData.name}</span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <span>{booleanToText(receiptData.name_valid)}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Ano do calendário:</strong>
                            {' '}
                            <span>{receiptData.calendar_year}</span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <span>{booleanToText(receiptData.calendar_year_valid)}</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Ano do exercício:</strong>
                            {' '}
                            <span>{receiptData.exercise_year}</span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <span>{booleanToText(receiptData.exercise_year_valid)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              </div>
            </Container>
          )}
    </React.Fragment>
  )
}

export default IrReceiptValues
