import React from 'react'
import _ from 'lodash'

import {
  Checkbox,
} from '@material-ui/core'

import { booleanToText, calculeTotal, formatToCurrency } from 'utils/functions'
import { FILE_TYPE } from 'utils/constants'

import Container from './styles'

const DcbeValues = ({ filesData, validTotal, groupedValues, onDeclarationCheck, readOnly }) => {
  if (_.isEmpty(filesData)) return <span>DCBE: Nenhum arquivo de Declaração</span>

  const renderTotals = () => {
    return (
      <div className="values-data-container">
        <p>
          <span>Valor Total:</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.DCBE, filesData),
            )}
          </span>
        </p>
        <p>
          <span>Valor Líquido Total:</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.DCBE, filesData, { filterChecked: true }),
            )}
          </span>
        </p>
        <p>
          <span>Valor Líquido Total (80%):</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.DCBE, filesData, { filterChecked: true, percentage: 80 }),
            )}
          </span>
        </p>
        <p>
          <span>Valores Corretos:</span>
          {' '}
          <span className="total-correct">
            {booleanToText(validTotal)}
          </span>
        </p>
      </div>
    )
  }

  const renderGroupedValues = () => {
    if (_.isEmpty(groupedValues)) return null

    return (
      <div className="table-container">
        <h3>Totais por Moeda</h3>

        <table>
          <thead>
            <tr>
              <th>Moeda</th>
              <th>Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {_.map(groupedValues, (grouped, i) => (
              <tr key={i.toString()}>
                <td>{grouped.currency}</td>
                <td>{grouped.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderGeneralData = (data) => {
    const generalData = _.get(data, 'general_data')
    if (_.isEmpty(generalData)) return null

    return (
      <div className="table-container">
        <h3>Identificação</h3>

        <table>
          <tbody>
            <tr>
              <td><strong>CPF:</strong></td>
              <td><span>{generalData.cpf}</span></td>
            </tr>
            <tr>
              <td><strong>Nome:</strong></td>
              <td><span>{generalData.name}</span></td>
            </tr>
            <tr>
              <td><strong>Status:</strong></td>
              <td><span>{generalData.status}</span></td>
            </tr>
            <tr>
              <td><strong>Ano Base:</strong></td>
              <td><span>{generalData.year_base}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const renderAssetSummary = (data) => {
    const assetSummary = _.get(data, 'asset_summary')
    if (_.isEmpty(assetSummary)) return null

    return (
      <div className="table-container">
        <h3>Resumo</h3>

        <table>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {_.map(assetSummary, (declaration, j) => (
              <tr key={j.toString()}>
                <td>{declaration.description}</td>
                <td>{formatToCurrency(declaration.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderAssetDetails = (data, fileIndex) => {
    const assetDetails = _.get(data, 'asset_details')
    if (_.isEmpty(assetDetails)) return null

    return (
      <div className="table-container">
        <h3>Detalhes</h3>

        <table>
          <thead>
            <tr>
              <th>Calcular</th>
              <th>Pais</th>
              <th>Descrição</th>
              <th>Moeda</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {_.map(assetDetails, (declaration, j) => (
              <tr key={j.toString()}>
                <td>
                  <Checkbox
                    checked={declaration.checked}
                    color="primary"
                    disabled={readOnly}
                    onChange={(e) => onDeclarationCheck(e, fileIndex, j)}
                  />
                </td>
                <td>{declaration.country}</td>
                <td>{declaration.description}</td>
                <td>{declaration.currency}</td>
                <td>{formatToCurrency(declaration.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderFiles = () => {
    return (
      <div className="values-files-container">
        {_.map(filesData, (data, fileIndex) => (
          <div className="values-file-container" key={fileIndex.toString()}>
            <div className="text-container">
              <p>
                <span>{`Arquivo #${fileIndex + 1}:`}</span>
                {' '}
                <span className="file-name">{data.name}</span>
              </p>
            </div>

            {renderGeneralData(data)}
            {renderAssetSummary(data)}
            {renderAssetDetails(data, fileIndex)}
          </div>
        ))}
      </div>
    )
  }

  return (
    <Container>
      <h2><ul><li>Declaração</li></ul></h2>

      <div style={{ width: '100%' }}>
        {renderTotals()}
        {renderGroupedValues()}
        {renderFiles()}
      </div>
    </Container>
  )
}

export default DcbeValues
