export const sectionsInfo = {
  exempt_income: {
    name: 'Rendimentos Isentos e Não Tributáveis',
    subsections: {
      scholarships_and_grants_without_donor_compensation: {
        name:
          '01. Bolsas de estudo e de pesquisa caracterizadas como doação, exceto médico-residente ou Pronatec, exclusivamente para proceder a estudos ou pesquisas e desde que os resultados dessas atividades não representem vantagem para o doador, nem importem contraprestação de serviços',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      scholarships_and_grants_for_participants: {
        name:
          '02. Bolsas de estudo e de pesquisa caracterizadas como doação, quando recebidas exclusivamente para proceder a estudos ou pesquisas, recebidas por médico-residente e por servidor da rede pública de educação profissional, científica e tecnológica que participe das atividades do Pronatec',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      insurance_payouts_for_death_or_permanent_disability: {
        name:
          '03. Capital das apólices de seguro ou pecúlio pago por morte do segurado, prêmio de seguro restituído em qualquer caso e pecúlio recebido de entidades de previdência privada em decorrência de morte ou invalidez permanente',
        columns: {},
      },
      termination_and_work_accident_compensation_including_fgts: {
        name:
          '04. Indenizações por rescisão de contrato de trabalho, inclusive a título de PDV, e por acidente de trabalho; e FGTS',
        columns: {
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cpf_cnpj: { name: 'CPF/CNPJ da Fonte Pagadora', type: 'cpf_cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      capital_gains_on_asset_sale_in_the_same_month: {
        name:
          '05. Ganho de capital na alienação de bem, direito ou conjunto de bens ou direitos da mesma natureza, alienados em um mesmo mês, de valor total de alienação até R$ 20.000,00, para ações alienadas no mercado de balcão, e R$ 35.000,00, nos demais casos',
        columns: {},
      },
      capital_gains_from_residential_property_sales_with_180_day_reinvestment: {
        name:
          '07. Ganho de capital na venda de imóveis residenciais para aquisição, no prazo de 180 dias, de imóveis residenciais localizados no Brasil e redução sobre o ganho de capital',
        columns: {},
      },
      capital_gains_from_foreign_currency_sales_under_5000: {
        name:
          '08. Ganho de capital na alienação de moeda estrangeira mantida em espécie cujo total de alienações, no ano-calendário, seja igual ou inferior ao equivalente a US$ 5,000.00',
        columns: {},
      },
      profits_and_dividends: {
        name: '09. Lucros e dividendos recebidos',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      tax_free_retirement_income_for_seniors_age_65_and_over: {
        name:
          '10. Parcela isenta de proventos de aposentadoria, reserva remunerada, reforma e pensão de declarante com 65 anos ou mais',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
          thirteenth_salary: '13º Salário',
        },
      },
      savings_accounts_mortgage_lci_lca_cra_cri: {
        name:
          '12. Rendimentos de cadernetas de poupança, letras hipotecárias, letras de crédito do agronegócio e imobiliário (LCA e LCI) e certificados de recebíveis do agronegócio e imobiliários (CRA e CRI)',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      income_of_small_business_parter_or_owner: {
        name:
          '13. Rendimento de sócio ou titular de microempresa ou empresa de pequeno porte optante pelo Simples Nacional, exceto pro labore, aluguéis e serviços prestados',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      asset_transfers_donations_and_inheritances: {
        name: '14. Transferências patrimoniais  doações e heranças',
        alternative_names: ['Transferências patrimoniais - doações e heranças'],
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CPF/CNPJ do Doador/Espólio', type: 'cnpj' },
          payer_name: { name: 'Nome do Doador/Espólio', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      income_tax_from_previous_years_compensated_judicially_this_year: {
        name:
          '16. Imposto sobre a renda de anos-calendário anteriores compensado judicialmente neste ano-calendário',
        columns: {
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cpf_cnpj: { name: 'CPF/CNPJ da Fonte Pagadora', type: 'cpf_cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      seventy_five_percent_of_salary_income_in_foreign_currency_from_br_government_servers_abroad: {
        name:
          '17. 75% (setenta e cinco por cento) dos rendimentos do trabalho assalariado recebidos em moeda estrangeira por servidores de autarquias ou repartições do Governo Brasileiro situadas no exterior, convertidos em reais',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      incorporation_reserves_into_capital_or_share_bonuses: {
        name: '18. Incorporação de reservas ao capital / Bonificações em ações',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      asset_transfers_due_to_marital_or_family_dissolution: {
        name:
          '19. Ganhos líquidos em operações com ouro, ativo financeiro, nas alienações realizadas até R$ 20.000,00 em cada mês',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      net_gains_from_operations_in_the_spot_market: {
        name:
          '20. Ganhos líquidos em operações no mercado à vista de ações negociadas em bolsas de valores nas alienações realizadas até R$ 20.000,00 em cada mês, para o conjunto de ações',
        alternative_names: [
          '20. Ganhos líquidos em operações no mercado à vista de ações negociadas em bolsas de valores nas alienações realizadas até R$ 20.000,00, em cada mês, para o conjunto de ações',
        ],
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      net_gains_from_gold_sales_under_20000_per_month: {
        name:
          '21. Ganhos líquidos em operações com ouro, ativo financeiro, nas alienações realizadas até R$ 20.000,00 em cada mês',
        alternative_names: [
          '21. Ganhos líquidos em operações com ouro, ativo financeiro, nas alienações realizadas até R$ 20.000,00, em cada mês',
        ],
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      recovery_of_losses_in_variable_income: {
        name:
          '22. Recuperação de Prejuízos em Renda Variável (bolsa de valores, de mercadorias, de futuros e assemelhados e fundos de investimento imobiliário)',
        columns: {},
      },
      gross_income_up_to_90_from_freight_services: {
        name:
          '23. Rendimento bruto, até o máximo de 90%, da prestação de serviços decorrente do transporte de carga e com trator, máquina de terraplenagem, colheitadeira e assemelhados',
        columns: {},
      },
      gross_income_up_to_40_from_passenger_transport_services: {
        name:
          '24. Rendimento bruto, até o máximo de 40%, da prestação de serviços decorrente do transporte de passageiros',
        columns: {},
      },
      income_tax_refund_from_previous_years: {
        name: '25. Restituição do imposto sobre a renda de anos-calendário anteriores',
        columns: {},
      },
      others: {
        name: '26. Outros',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cpf_cnpj: { name: 'CPF/CNPJ da Fonte Pagadora', type: 'cpf_cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          description: { name: 'Descrição', type: 'value' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      others_99: {
        name: '99. Outros',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cpf_cnpj: { name: 'CPF/CNPJ da Fonte Pagadora', type: 'cpf_cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          description: { name: 'Descrição', type: 'value' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
    },
  },
  exclusive_taxation_income: {
    name: 'Rendimentos Sujeitos à Tributação Exclusiva/Definitiva',
    subsections: {
      thirteenth_salary: {
        name: '01. 13º salário',
        columns: {},
      },
      capital_gains_from_sale_of_assets_and_or_rights: {
        name: '02. Ganhos de capital na alienação de bens e/ou direitos',
        columns: {},
      },
      capital_gains_from_sale_of_assets_rights_and_investments_in_foreign_currency: {
        name:
          '03. Ganhos de capital na alienação de bens, direitos e aplicações financeiras adquiridos em moeda estrangeira',
        columns: {},
      },
      capital_gains_from_sale_of_foreign_currency_in_cash: {
        name: '04. Ganhos de capital na alienação de moeda estrangeira em espécie',
        columns: {},
      },
      net_gains_from_variable_income_stocks_futures_and_reits: {
        name:
          '05. Ganhos líquidos em renda variável (bolsa de valores, de mercadorias, de futuros e assemelhados e fundos de investimento imobiliário)',
        columns: {},
      },
      income_from_financial_investments: {
        name: '06. Rendimentos de aplicações financeiras',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      accumulated_income_received: {
        name: '07. Rendimentos recebidos acumuladamente',
        columns: {},
      },
      net_gains_from_fixed_income_operations: {
        name: '08. Ganhos líquidos em operações de renda fixa',
        columns: {},
      },
      net_gains_from_variable_income_operations: {
        name: '09. Ganhos líquidos em operações de renda variável',
        columns: {},
      },
      interest_on_own_capital: {
        name: '10. Juros sobre capital próprio',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      profit_or_results_sharing: {
        name: '11. Participação nos lucros ou resultados',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cnpj: { name: 'CNPJ da Fonte Pagadora', type: 'cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
      others: {
        name: '12. Outros',
        columns: {
          beneficiary: { name: 'Beneficiário', type: 'beneficiary' },
          cpf: { name: 'CPF', type: 'cpf' },
          payer_cpf_cnpj: { name: 'CPF/CNPJ da Fonte Pagadora', type: 'cpf_cnpj' },
          payer_name: { name: 'Nome da Fonte Pagadora', type: 'name' },
          description: { name: 'Descrição', type: 'value' },
          value: { name: 'Valor', type: 'currency' },
        },
      },
    },
  },
}
