import React, { useState } from 'react'
import _ from 'lodash'

import { Button } from '@material-ui/core'
import { booleanToText, formatToCurrency } from 'utils/functions'

import { Alert } from '@material-ui/lab'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import Container from '../styles'
import NoDataFoundMessage from '../../NoDataFoundMessage'
import { Colors } from 'utils/constants'

const DebtsAndEncumbrances = ({ irDeclaration }) => {
  const [showDebtsList, setShowDebtsList] = useState(false)
  const debtsData = irDeclaration?.debts_and_encumbrances

  const renderTotals = () => {
    return (
      <div className="values-data-container">
        <p>
          <span>Valor Total Pago no Ano Atual:</span>
          {' '}
          <span className="total-correct">{formatToCurrency(debtsData.current_year_total_value)}</span>
        </p>
        <p>
          <span>Valor Total no Ano Passado:</span>
          {' '}
          <span className="total-correct">{formatToCurrency(debtsData.last_year_total_value)}</span>
        </p>
        <p>
          <span>Valor Total no Ano Retrasado:</span>
          {' '}
          <span className="total-correct">{formatToCurrency(debtsData.year_before_last_total_value)}</span>
        </p>
      </div>
    )
  }

  const renderDebtItems = (debtsItems, totalPages) => {
    const debtItems = debtsItems.items
    if (_.isEmpty(debtItems)) return null

    const pages = _.groupBy(debtItems, 'page')

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Descrição</th>
              <th>Valor Ano Retrasado</th>
              <th>Valor Ano Passado</th>
              <th>Valor Atual</th>
            </tr>
          </thead>
          <tbody>
            {_.map(pages, (pageItems) => {
              const currentPageNum = pageItems[0]?.page
              const currentPageHasProblems = debtsItems.pages_with_problems.includes(currentPageNum)
              return (
                <React.Fragment key={pageItems[0]?.id}>
                  <tr
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <td
                      style={{
                        margin: '10px 0',
                        textAlign: 'left',
                        borderLeft: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        borderRight: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                      }}
                      colSpan={8}
                    >
                      <span style={{ fontSize: '1.34rem' }}>
                        {`Página ${currentPageNum} ${totalPages ? `de ${totalPages}` : ''}`}

                        {currentPageHasProblems && (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              width: '100%',
                              margin: '5px 0',
                            }}
                          >
                            <Alert severity="error" style={{ width: '100%' }}>
                              Houve um erro na extração de dados dessa página. Um ou mais itens
                              podem estar inválidos, repetidos ou faltando.
                            </Alert>
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                  {_.map(pageItems, (item) => (
                    <tr key={item?.id}>
                      <td style={{ padding: '20px 0', borderLeft: currentPageHasProblems ? `1px solid ${Colors.red}` : null }}>{item.debt_code}</td>
                      <td style={{ padding: '20px 0' }}>{item.debt_description}</td>
                      <td style={{ padding: '20px 0' }}>{formatToCurrency(item.year_before_last_value)}</td>
                      <td style={{ padding: '20px 0' }}>{formatToCurrency(item.last_year_value)}</td>
                      <td
                        style={{
                          padding: '20px 0', borderRight: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        }}
                      >
                        {formatToCurrency(item.current_year_value)}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const renderDebtsData = () => {
    const totalPages = irDeclaration?.total_pages

    if (_.isEmpty(debtsData?.items)) {
      return null
    }

    return (
      <div className="values-files-container">
        <Button
          startIcon={showDebtsList ? <ArrowDropDown /> : <ArrowRight />}
          onClick={() => setShowDebtsList(!showDebtsList)}
        >
          <strong style={{ fontSize: '1.34rem' }}>
            {showDebtsList ? 'Esconder itens' : 'Exibir itens'}
          </strong>
        </Button>
        {showDebtsList && (
          <div className="values-file-container">
            <div className="text-container">
              <p>
                <span>Todos os itens estão corretos:</span>
                {' '}
                <span
                  className="file-correct"
                  style={{
                    color: _.isEmpty(debtsData.pages_with_problems) ? null : 'rgb(255, 23, 68)',
                  }}
                >
                  {booleanToText(_.isEmpty(debtsData.pages_with_problems))}
                </span>
              </p>
            </div>

            {renderDebtItems(debtsData, totalPages)}
          </div>
        )}
      </div>
    )
  }

  return (
    <Container>
      <h2>Dívidas e Ônus Reais</h2>
      { _.isEmpty(irDeclaration?.debts_and_encumbrances) ? <NoDataFoundMessage />  : (
        <div>
          {renderTotals()}
          {renderDebtsData()}
        </div>
    )}
    </Container>
  )
}

export default DebtsAndEncumbrances
