import React from 'react'
import _ from 'lodash'


import AssetsDeclaration from './AssetsDeclaration'
import TaxpayerIdentification from './TaxpayerIdentification'
import NoDataFoundMessage from '../NoDataFoundMessage'
import DebtsAndEncumbrances from './DebtsAndEncumbrances'
import SubsectionedIncome from './Income/SubsectionedIncome'
import SingleTableIncome from './Income/SingleTableIncome'

const IrValues = ({ irDeclaration, onDeclarationCheck, readOnly }) => {
  return (
    <div style={{ width: '100%' }}>
      <div>
        <h2>
          <ul>
            <li>Arquivo de Declaração</li>
          </ul>
        </h2>
      </div>
      <br />
      <div>
        {_.isEmpty(irDeclaration) ? <NoDataFoundMessage message='Arquivo de declaração não enviado' /> : (
          <div className='values-files-container'>
            <TaxpayerIdentification irDeclaration={irDeclaration} />
            <AssetsDeclaration irDeclaration={irDeclaration} onDeclarationCheck={onDeclarationCheck} readOnly={readOnly} />
            <br />
            <SingleTableIncome irDeclaration={irDeclaration} sectionId='income_from_legal_person_to_holder' />
            <br />
            <SingleTableIncome irDeclaration={irDeclaration} sectionId='income_from_legal_person_to_dependents' />
            <br />
            <SubsectionedIncome irDeclaration={irDeclaration} sectionId='exempt_income' />
            <br />
            <SubsectionedIncome irDeclaration={irDeclaration} sectionId='exclusive_taxation_income' />
            <br />
            <DebtsAndEncumbrances irDeclaration={irDeclaration} />
            <br />
          </div>
        )}

      </div>
    </div>
  )
}

export default IrValues
