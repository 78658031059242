import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'
import { Document, Page, pdfjs } from 'react-pdf'
import { saveAs } from 'file-saver'

import {
  Button,
  CircularProgress,
  Fab,
  Input,
} from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { getConsultResponse, postFile, postGenerateCsv, putUpdateRequest } from 'services/apiIrMain'
import {
  dateIsOlderThanYesterday,
  getFileNameExtension,
} from 'utils/functions'
import { FILE_TYPE } from 'utils/constants'

import IrValues from './IrValues'
import IrReceiptValues from './IrReceiptValues'
import DcbeValues from './DcbeValues'
import DcbeReceiptValues from './DcbeReceiptValues'
import ListConsults from './ListConsults'
import Container from './styles'
import AlertMessage from 'ui/components/AlertMessage'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Home = () => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector(() => process.env.REACT_APP_API_KEY)

  const tokenDocument = useRef()
  const irInputFile = useRef()
  const dcbeInputFile = useRef()

  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [imgsBase64, setImgsBase64] = useState([])
  const [sendedFiles, setSendedFiles] = useState(false)
  const [showStillProcessingMessage, setShowStillProcessingMessage] = useState(false)
  const [lastSendDate, setLastSendDate] = useState(null)

  const [requestResponse, setRequestResponse] = useState(null)

  const reportAxiosError = (err, message = 'Ocorreu um erro inesperado') => {
    Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || message
      toast.error(messageError)
  }

  const resetStates = (options = {}) => {
    const {
      resetToken = true,
      resetImgsBase64 = true,
      resetSelectedItem = true,
    } = options

    if (resetToken) tokenDocument.current = null
    if (resetImgsBase64) setImgsBase64([])
    if (resetSelectedItem) setSelectedItem(null)
    irInputFile.current.value = null
    dcbeInputFile.current.value = null
    setLastSendDate(null)
    setLoading(false)
    setSendedFiles(false)
    setRequestResponse(null)
  }

  const handleFile = async (event, fileType) => {
    if (sendedFiles === true) {
      setImgsBase64([])
    }

    const { files } = event.target
    const newFiles = []

    _.each(files, file => {
      // TODO: check if file is image or pdf
      // TODO: validar tamanho dos arquivos
      const reader = new FileReader()

      reader.onloadend = () => {
        newFiles.push({
          name: file.name,
          base64: reader.result,
          fileType: fileType,
        })
        if (_.size(newFiles) === _.size(files)) {
          const filesUniq = _.differenceBy(newFiles, imgsBase64, 'base64')
          if (!_.isEmpty(filesUniq)) setImgsBase64(prevVal => ([ ...prevVal, ...filesUniq ]))
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const handleDocumentPdfLoadSuccess = ({ numPages }, fileIndex) => {
    const newImgsBase64 = _.cloneDeep(imgsBase64)
    _.set(
      newImgsBase64,
      `${fileIndex}.pdfNumPages`,
      numPages,
    )

    setImgsBase64(newImgsBase64)
  }

  const handleDocumentPdfLoadError = (err) => {
    console.error(err.message)
    Sentry.captureException(err)
  }

  const handleFileDelete = (fileIndex) => {
    const newFiles = _.cloneDeep(imgsBase64)
    newFiles.splice(fileIndex, 1)
    setImgsBase64(newFiles)
  }
  const loadFilesResult = async (token, intervalId = null) => {
    let res = null
    setLoading(true)
    setShowStillProcessingMessage(false)

    try {
      res = await getConsultResponse(
        apiKey,
        userId,
        token,
      )
    } catch (err) {
      Sentry.captureException(err)
      setLoading(false)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter dados'
      toast.error(messageError)
      return
    }

    if (!intervalId && res.status === 202) {
      setLoading(false)
      setShowStillProcessingMessage(true)
      return
    }

    if (res.status !== 200) return

    if(intervalId) {
      clearInterval(intervalId)
    }

    setSendedFiles(true)

    const msgInfos = _.get(res.data, 'msg_infos')
    if (!_.isEmpty(msgInfos)) {
      toast.error(_.get(msgInfos, '0.msg'))
      return
    }
    const msgErrors = _.get(res.data, 'msg_errors')
    if (!_.isEmpty(msgErrors)) {
      toast.error(_.get(msgErrors, '0.msg'))
      return
    }

    setRequestResponse(res.data)

    setLoading(false)
  }

  const getFilesResult = (token) => {
    const intervalId = setInterval(async () => {
      await loadFilesResult(token, intervalId)
    }, 5000)
  }


  const handleExportCsv = async () => {
    let res = null

    if (_.isEmpty(requestResponse?.ir_response?.declaration)) {
      toast.error('Recurso indisponível. Arquivo de declaração não enviado.')
      return
    }

    try {
      res = await postGenerateCsv(
        apiKey,
        userId,
        tokenDocument.current,
      )
    } catch (err) {
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro exportar CSV'
      toast.error(messageError)
      return
    }

    const blobText = new Blob([res.data], { type: 'text/csv;charset=utf-8' })
    saveAs(blobText, 'exported.csv')
  }

  const sendFile = async (fileContent, position, filesQuantity, token = null) => {
    const res = await postFile(
      apiKey,
      userId,
      {
        token: token,
        name: fileContent.name,
        base64: fileContent.base64,
        fileType: fileContent.fileType,
        filePosition: position,
        totalRequests: filesQuantity,
      },
    )

    return res.data.token
  }

  const handleSubmit = async (files) => {
    if (_.isEmpty(files)) return

    setLoading(true)

    const filesTreated = _.map(files, (file) => {
      return {
        ...file,
        base64: file.base64.substr(file.base64.indexOf(',') + 1),
      }
    })

    const filesLength = filesTreated.length

    try {
      tokenDocument.current = await sendFile(filesTreated[0], 1, filesLength)
    } catch (err) {
      Sentry.captureException(err)
      setLoading(false)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar arquivos'
      toast.error(messageError)
      return
    }

    try {
      const promises = _.map(filesTreated.slice(1), (fileTreated, i) => {
        return sendFile(fileTreated, i + 2, filesLength, tokenDocument.current)
      })
      await Promise.all(promises)
    } catch (err) {
      Sentry.captureException(err)
      setLoading(false)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar arquivos'
      toast.error(messageError)
      return
    }

    getFilesResult(tokenDocument.current)
  }

  const handleIrDeclarationCheck = (event, itemId) => {
    putUpdateRequest(apiKey, userId, tokenDocument.current, {
      ir_assets: [{ id: itemId, checked: event.target.checked }],
    }).then((response) => {
      setRequestResponse(response.data)
    })
  }


  const handleDcbeDeclarationCheck = (event, fileIndex, declarationIndex) => {
    const newRequestResponse = _.cloneDeep(requestResponse)

    _.set(
      requestResponse.ir_response,
      `${fileIndex}.asset_details.${declarationIndex}.checked`,
      event.target.checked,
    )

    setRequestResponse(newRequestResponse)
  }

  const handleFinalizeOperation = async () => {
    try {
      await putUpdateRequest(
        apiKey,
        userId,
        tokenDocument.current,
        { conclude: true },
      )
    } catch (err) {
      reportAxiosError(err, 'Erro ao finalizar a operação')
    }

    resetStates()
    setLastSendDate((new Date()).toString())
  }

  const handleSelectItem = (item) => {
    resetStates()

    tokenDocument.current = item.token
    setSelectedItem(item)
    loadFilesResult(item.token)
  }

  const renderFileTypeIr = () => {
    const irResponse = requestResponse?.ir_response
    return (
      <React.Fragment>
        <IrReceiptValues receiptData={irResponse?.receipt} />

        <br />

        <IrValues
          irDeclaration={irResponse?.declaration}
          onDeclarationCheck={handleIrDeclarationCheck}
          readOnly={!_.isEmpty(selectedItem)}
        />
      </React.Fragment>
    )
  }

  const renderFileTypeDcbe = () => {
    const dcbeResponse = requestResponse?.dcbe_response

    return (
      <React.Fragment>
        <DcbeReceiptValues
          filesData={dcbeResponse?.receipt}
        />

        <DcbeValues
          filesData={dcbeResponse}
          validTotal={dcbeResponse?.valid_total}
          onDeclarationCheck={handleDcbeDeclarationCheck}
          groupedValues={dcbeResponse?.groupedValues}
          hasSelectedItem={!_.isEmpty(selectedItem)}
        />
      </React.Fragment>
    )
  }

  const renderStillProcessingMessage = () => {
    if (!showStillProcessingMessage || _.isEmpty(selectedItem)) return

    const isOld = dateIsOlderThanYesterday(selectedItem?.created_at)
    return (
      <AlertMessage
        action={<Button onClick={() => loadFilesResult(selectedItem?.token)}>Recarregar</Button>}
        iconType={isOld ? 'error' : 'loading' }
        severity={isOld ? 'error' : 'info'}
        title={isOld ? 'Ocorreu um erro' : 'Por favor, aguarde'}
        content={isOld
          ? 'Embora esta consulta seja muito antiga, parece que ela não foi processada corretamente.'
          : 'Esta consulta ainda está sendo processada.'}
      />
    )
  }

  return (
    <Container>
      <div>
        <ListConsults onSelectItem={handleSelectItem} lastUpdatedDate={lastSendDate} />
      </div>

      <div className="files-container">
        <div className="files-btns-container">
          <div className="btn-input-file-container">
            <div className="btn-item">
              <span className="btn-item-text">Arquivos IR:</span>
              <Input
                inputRef={ref => { irInputFile.current = ref }}
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                disabled={loading || sendedFiles || !_.isEmpty(selectedItem)}
                inputProps={{ multiple: true }}
                onChange={(e) => handleFile(e, FILE_TYPE.IR)}
                type="file"
              />
            </div>

            <div className="btn-item">
              <span className="btn-item-text">Arquivos DCBE:</span>
              <Input
                inputRef={ref => { dcbeInputFile.current = ref }}
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                disabled={loading || sendedFiles || !_.isEmpty(selectedItem)}
                inputProps={{ multiple: true }}
                onChange={(e) => handleFile(e, FILE_TYPE.DCBE)}
                type="file"
              />
            </div>
          </div>

          <div className="btn-item">
            <Button
              color="primary"
              disabled={loading || sendedFiles || _.isEmpty(imgsBase64)}
              onClick={() => handleSubmit(imgsBase64)}
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </div>

        <div className="files-files-container">
          <React.Fragment>
            {_.map(imgsBase64, (fileData, i) => (
              <div key={i.toString()}>
                <div className="img-btn-delete-container">
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleFileDelete(i)}
                  >
                    <DeleteOutlinedIcon />
                  </Fab>
                </div>
                {
                  getFileNameExtension(fileData.name) === 'pdf' ? (
                    <Document
                      file={fileData.base64}
                      onLoadSuccess={(data) => handleDocumentPdfLoadSuccess(data, i)}
                      onLoadError={handleDocumentPdfLoadError}
                    >
                      {_.map(new Array(fileData.pdfNumPages), (_val, indexPage) => (
                        <Page pageNumber={indexPage + 1} key={indexPage.toString()} className="PDF-Page" />
                      ))}
                    </Document>
                  ) : <img src={fileData.base64} alt="imagem" width="100%" />
                }
              </div>
            ))}
          </React.Fragment>
        </div>
      </div>

      <div className="content-container">
        <div className="content-btns-container">
          <Button
            disabled={!sendedFiles}
            onClick={() => handleExportCsv()}
            variant="contained"
          >
            Exportar para CSV
          </Button>

          {!_.isEmpty(selectedItem) ? (
            <Button
              color="primary"
              disabled={loading}
              onClick={() => resetStates()}
              variant="contained"
            >
              Limpar!
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={!sendedFiles}
              onClick={() => handleFinalizeOperation()}
              variant="contained"
            >
              Finalizar Operação!
            </Button>
          )}
        </div>

        <div className="values-container">
          {
            loading
            ? <React.Fragment>
                <CircularProgress />
              </React.Fragment>
            : !sendedFiles ? renderStillProcessingMessage() : (
              <React.Fragment>
                <h2 style={{ alignSelf: 'flex-start', marginBottom: 10, marginTop: 30 }}>
                  Tipo: IR
                </h2>
                {renderFileTypeIr()}

                <h2 style={{ alignSelf: 'flex-start', marginBottom: 10, marginTop: 30 }}>
                  Tipo: DCBE
                </h2>
                {renderFileTypeDcbe()}
              </React.Fragment>
            )
          }
        </div>
      </div>
    </Container>
  )
}

export default Home
