import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { ButtonRecaptcha } from 'ui/components'

import { confirmRecoverPassword } from 'services/apiAuth'
import {
  isNetworkError,
  isPasswordValid
} from 'utils/functions'

import styles from './styles'

const PasswordConfirmRecover = () => {
  const classes = styles()
  const history = useHistory()

  const email = useSelector(state => state.auth.destination)

  const [code, setCode] = useState('')
  const [usernameEmail, setUsernameEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const handleSubmit = async (recaptchaToken) => {
    setError(false)

    try {
      const formData = new FormData()
      formData.append('code', code)
      formData.append('username_email', usernameEmail)
      formData.append('password', password)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', recaptchaToken)

      const res = await confirmRecoverPassword(formData)

      if (res.status === 200) {
        return history.push('/')
      }
    } catch (err) {
      if (!isNetworkError(err)) {
        const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao confirmar senha'
        toast.error(messageError)
      }

      return null
    }

    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <img src="/assets/images/logos/logo.svg" alt="Logo" className={classes.logo} />
        <div className={classes.titleSidebarContainer}>
          <h1 className={classes.titleSidebar}>Confirmar Cadastro</h1>
          <p className={classes.subtitleSidebar}>Faça a confirmação do cadastro em que será alterada a senha!</p>
        </div>
      </div>
      <div className={classes.main}>
        <h1 className={classes.title}>Digite o código recebido por email:</h1>
        <p>
          O código foi enviado para o email:
          {email}
        </p>
        <div className={classes.inputsContainer}>
          <div className={classes.formContainer}>
            <TextField
              id="code"
              label="Código recebido por email"
              className={classes.textField}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              type="number"
              required={true}
              autoCapitalize="off"
              autoComplete="one-time-code"
              autoCorrect="off"
            />
            <TextField
              id="email"
              label="Nome de Usuário ou E-mail utilizado"
              className={classes.textField}
              value={usernameEmail}
              onChange={(e) => setUsernameEmail(e.target.value)}
              type="email"
              required={true}
              autoCapitalize="off"
              autoComplete="email"
              autoCorrect="off"
            />
            <TextField
              id="password"
              label="Senha"
              className={classes.textField}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              required={true}
              autoCapitalize="off"
              autoComplete="new-password"
              autoCorrect="off"
              error={password.length !== 0 ? !isPasswordValid(password) : false}
              helperText={
                password.length !== 0
                  ? isPasswordValid(password)
                    ? false
                    : 'A senha precisa ter pelo menos 1 letra maiuscula, 1 minuscula, 1 número e um caracter especial, e no mínimo de 8 caracteres.'
                  : false
              }
            />
          </div>
          {error ? (
            <p>
              <strong className={classes.error}>
                Já existe uma conta cadastrada com esse email.
                <br />
                Por favor, refaça o cadastro utilizando um email diferente.
              </strong>
            </p>
          ) : null}
        </div>

        <ButtonRecaptcha
          text="Acessar"
          onClick={(token) => handleSubmit(token)}
        />

        <Typography>
          Lembrei minha senha.
          {' '}
          <Link to="/">Entrar</Link>
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(PasswordConfirmRecover)
