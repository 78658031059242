import React, { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Button, CircularProgress } from '@material-ui/core'

import styles from './styles'

export default ({ onClick, action = null, text = 'Acessar' }) => {
  const classes = styles()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [loading, setLoading] = useState(false)

  const handleReCaptchaVerify = useCallback(async () => {
    setLoading(true)

    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      setLoading(false)
      return
    }
    const token = await executeRecaptcha(action)

    await onClick(token)
    setLoading(false)
  }, [action, executeRecaptcha, onClick])

  const renderText = () => {
    if (loading) return <CircularProgress className={classes.progress} />

    return text
  }

  const renderRecaptchaBadge = () => {
    return (
      <div className={classes.recaptchaBadge}>
        This site is protected by reCAPTCHA and the Google,
        {' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a>
        {' '}
        and
        {' '}
        <a href="https://policies.google.com/terms">Terms of Service</a>
        {' '}
        apply.
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => handleReCaptchaVerify()}
          disabled={loading}
        >
          {renderText()}
        </Button>
      </div>

      {renderRecaptchaBadge()}
    </React.Fragment>
  )
}
