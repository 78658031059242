import React from 'react'
import _ from 'lodash'


import { makeStyles } from '@material-ui/core'
import NoDataFoundMessage from '../../NoDataFoundMessage'
import styles from './styles'
import Container from '../styles'


const TaxpayerIdentification = ({ irDeclaration }) => {
  const classes = makeStyles(() => styles)()

  const buildAddressString = (address) => {
    const addressParts = [
      address.street_address,
      address.number,
      address.complement,
      address.neighborhood,
      address.city,
      address.uf,
      address.zip_code ? `CEP: ${address.zip_code}` : null,
    ]

    return _.compact(addressParts).join(' - ')
  }

  const renderTaxpayerIdentification = () => {
    const taxpayerIdentification = irDeclaration?.taxpayer_identification
    if (_.isEmpty(taxpayerIdentification)) return <NoDataFoundMessage />

    return (
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>CPF:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.cpf}</span>
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>Nome:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.name}</span>
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>Ocupação Principal:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.main_occupation}</span>
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>Natureza da Ocupação:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.occupation_nature}</span>
              </td>
            </tr>
            {!_.isEmpty(taxpayerIdentification.contact_and_address) && (
              <tr>
                <td style={{ fontSize: '1.5rem' }}>
                  <strong>E-mail:</strong>
                </td>
                <td style={{ fontSize: '1.5rem' }}>
                  <span>{taxpayerIdentification.contact_and_address.email}</span>
                </td>
              </tr>
            )}
            {!_.isEmpty(taxpayerIdentification.contact_and_address) && (
              <tr>
                <td style={{ fontSize: '1.5rem' }}>
                  <strong>Telefone:</strong>
                </td>
                <td style={{ fontSize: '1.5rem' }}>
                  <span>{taxpayerIdentification.contact_and_address.phone}</span>
                </td>
              </tr>
            )}
            {!_.isEmpty(taxpayerIdentification.contact_and_address) && (
              <tr>
                <td style={{ fontSize: '1.5rem' }}>
                  <strong>Celular:</strong>
                </td>
                <td style={{ fontSize: '1.5rem' }}>
                  <span>{taxpayerIdentification.contact_and_address.cell_phone}</span>
                </td>
              </tr>
            )}
            {!_.isEmpty(taxpayerIdentification.contact_and_address) && (
              <tr>
                <td style={{ fontSize: '1.5rem' }}>
                  <strong>Endereço:</strong>
                </td>
                <td style={{ fontSize: '1.5rem' }}>
                  <span>{buildAddressString(taxpayerIdentification.contact_and_address)}</span>
                </td>
              </tr>
            )}
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>Tipo de Declaração:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.type_ir}</span>
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>Ano Calendário:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.calendar_year}</span>
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: '1.5rem' }}>
                <strong>Ano Exercício:</strong>
              </td>
              <td style={{ fontSize: '1.5rem' }}>
                <span>{taxpayerIdentification.exercise_year}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }


  return (
    <Container>
      <h2>
        Identificação
      </h2>

      <div>
        {renderTaxpayerIdentification()}
        <br />
      </div>
    </Container>
  )
}

export default TaxpayerIdentification
