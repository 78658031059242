import React, { useState } from 'react'
import _ from 'lodash'

import { Button, Divider } from '@material-ui/core'
import { booleanToText, formatToCurrency } from 'utils/functions'

import { Alert } from '@material-ui/lab'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import Container from '../../styles'
import { sectionsInfo } from './sectionsInfo'
import NoDataFoundMessage from 'ui/views/Home/NoDataFoundMessage'
import { Colors } from 'utils/constants'

const SubsectionedIncome = ({ irDeclaration, sectionId }) => {
  const incomeData = irDeclaration[sectionId]
  const [showIncomeSubsections, setShowIncomeSubsections] = useState(false)

  const renderTotals = () => {
    const totalValid = incomeData.valid_total
    return (
      <div className="values-data-container">
        <p>
          <span>Valor Total:</span>
          {' '}
          <span className="total-correct">{formatToCurrency(incomeData.total_value)}</span>
          <br></br>
          <span>Valores Corretos:</span>
          {' '}
          <span className="total-correct" style={{ color: totalValid ? null : Colors.red }}>{booleanToText(totalValid)}</span>
        </p>
      </div>
    )
  }

  const getSubsectionColumns = (subsectionId) => {
    const sectionMetaData = sectionsInfo[sectionId]
    if (!sectionMetaData) {
      return []
    }

    const subsections = sectionMetaData?.subsections
    if (!subsections) {
      return []
    }

    return subsections[subsectionId]?.columns
  }
  const createRowStyle = (row, index, currentPageHasProblems) => (
    {
      padding: '20px 0',
      borderLeft:
        currentPageHasProblems && index === 0
          ? `1px solid ${Colors.red}`
          : null,
      borderRight:
        currentPageHasProblems && index === row.length - 1
          ? `1px solid ${Colors.red}`
          : null,
    }
  )
  const renderSubsectionItems = (subsection, subsectionId, totalPages) => {
    const { items } = subsection
    if (_.isEmpty(items)) return null

    const pages = _.groupBy(items, 'page')
    const subsectionColumns = getSubsectionColumns(subsectionId)

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {_.map(subsectionColumns, (col, key) => (
                <th key={key}>{col.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(pages, (pageRows) => {
              const currentPageNum = pageRows[0]?.page
              const currentPageHasProblems = false // subsectionItems.pages_with_problems.includes(currentPageNum)
              return (
                <React.Fragment key={pageRows[0]?.id}>
                  <tr
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <td
                      style={{
                        margin: '10px 0',
                        textAlign: 'left',
                        borderLeft: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        borderRight: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                      }}
                      colSpan={8}
                    >
                      <span style={{ fontSize: '1.34rem' }}>
                        {`Página ${currentPageNum} ${totalPages ? `de ${totalPages}` : ''}`}

                        {currentPageHasProblems && (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              width: '100%',
                              margin: '5px 0',
                            }}
                          >
                            <Alert severity="error" style={{ width: '100%' }}>
                              Houve um erro na extração de dados dessa página. Um ou mais itens
                              podem estar inválidos, repetidos ou faltando.
                            </Alert>
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                  {_.map(pageRows, (row) => (
                    <tr key={row?.id}>
                      {Object.entries(_.omit(row, ['id', 'page'])).map(([k, v], index) => (
                        <td
                          key={k}
                          style={createRowStyle(row, index, currentPageHasProblems)}
                        >
                          {subsectionColumns[k].type === 'currency' ? formatToCurrency(v) : v}
                        </td>
                        ),
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const renderSubsection = () => {
    const totalPages = irDeclaration?.total_pages

    if (_.isEmpty(incomeData?.subsections)) {
      return null
    }

    return (
      <div className="values-files-container">
        <Button
          startIcon={showIncomeSubsections ? <ArrowDropDown /> : <ArrowRight />}
          onClick={() => setShowIncomeSubsections(!showIncomeSubsections)}
        >
          <strong style={{ fontSize: '1.34rem' }}>
            {showIncomeSubsections ? 'Esconder itens' : 'Exibir itens'}
          </strong>
        </Button>
        {showIncomeSubsections && (
          <div className="values-file-container">
            <div className="text-container">
              {/* <p>
                <span>Todos os itens estão corretos:</span>
                {' '}
                <span
                  className="file-correct"
                  style={{
                    color: _.isEmpty(incomeData.pages_with_problems) ? null : 'rgb(255, 23, 68)',
                  }}
                >
                  {booleanToText(_.isEmpty(incomeData.pages_with_problems))}
                </span>
              </p> */}
            </div>
            {_.map(incomeData?.subsections, (subsection, id) => (
              <div key={subsection.name}>
                <h3>{subsection.name}</h3>
                <br />
                <p style={{ fontSize: '1.17em' }}>
                  <b>{'Valor Total: '}</b>
                  {formatToCurrency(subsection.total_value)}
                </p>
                <br />
                <p style={{ fontSize: '1.17em' }}>
                  <b>{'Valores Corretos: '}</b>
                  <b style={{ color: subsection.valid_total ? null : Colors.red}}>{booleanToText(subsection.valid_total)}</b>
                </p>
                <br />
                {renderSubsectionItems(subsection, id, totalPages)}

                <Divider />
                <br />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <Container>
      <h2>{sectionsInfo[sectionId]?.name}</h2>
      {_.isEmpty(incomeData) ? (
        <NoDataFoundMessage />
      ) : (
        <div>
          {renderTotals()}
          {renderSubsection()}
        </div>
      )}
    </Container>
  )
}

export default SubsectionedIncome
