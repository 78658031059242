import { AccessTime, ErrorOutline } from "@material-ui/icons"
import { Alert, AlertTitle } from "@material-ui/lab"
import { makeStyles } from "@material-ui/styles"
import React from "react"

const styles = makeStyles((theme) => ({
  alert: {
    marginTop: 20,
    backgroundColor: '#eee',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  alertAccesTimeIcon: {
    color: theme.palette.primary.main,
  },
}))

const AlertMessage = ({ title, content, action, iconType, severity }) => {
  const classes = styles()

  const selectIcon = () => {
    if (iconType === 'error') {
      return <ErrorOutline />
    }
    if (iconType === 'loading') {
      return <AccessTime className={classes.alertAccesTimeIcon} />
    }
    return undefined
  }

  return (
    <Alert
      className={classes.alert}
      action={action}
      icon={selectIcon()}
      severity={severity}
      variant="outlined"
    >
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  )
}

export default AlertMessage
