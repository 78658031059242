import React, { useState } from 'react'
import _ from 'lodash'

import { Button, Divider } from '@material-ui/core'
import { formatToCurrency } from 'utils/functions'

import { Alert } from '@material-ui/lab'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import Container from '../../styles'
import { sectionsInfo } from './sectionsInfo'
import NoDataFoundMessage from 'ui/views/Home/NoDataFoundMessage'
import { Colors } from 'utils/constants'

const SingleTableIncome = ({ irDeclaration, sectionId }) => {
  const incomeData = irDeclaration[sectionId]
  const [showIncomeItems, setShowIncomeItems] = useState(false)
  const sectionColumns = sectionsInfo[sectionId]?.columns

  const renderTotals = () => {
    const totalColumns = _.pickBy(sectionColumns, (col) => !_.isEmpty(col.total_value_column))
    const allValuesValid = _.every(incomeData.total_values, (col) => col.valid)
    return (
      <div className="values-data-container">
        {_.map(totalColumns, (col, key) => {
          return (
            <p key={key}>
              <span>{`${col.total_value_column}: `}</span>
              <span
                style={{ color: incomeData.total_values[key].valid ?  null : Colors.red }}
                className="total-correct"
              >
                {formatToCurrency(incomeData.total_values[key].amount)}
              </span>
            </p>
          )
        })}

        <p>
          <span>{'Valores Corretos: '}</span>
          <span
            style={{ color: allValuesValid ? null : Colors.red }}
            className="total-correct"
          >
            {allValuesValid ? 'SIM' : 'NÃO'}
          </span>
        </p>
      </div>
    )
  }

  const createRowStyle = (row, index, currentPageHasProblems) => ({
    padding: '20px 0',
    borderLeft: currentPageHasProblems && index === 0 ? `1px solid ${Colors.red}` : null,
    borderRight:
      currentPageHasProblems && index === row.length - 1 ? `1px solid ${Colors.red}` : null,
  })
  const renderSectionItems = () => {
    const { items } = incomeData
    const totalPages = irDeclaration?.total_pages

    if (_.isEmpty(items)) return null

    const pages = _.groupBy(items, 'page')
    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {_.map(sectionColumns, (col, key) => (
                <th key={key}>{col.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(pages, (pageRows) => {
              const currentPageNum = pageRows[0]?.page
              const currentPageHasProblems = false // subsectionItems.pages_with_problems.includes(currentPageNum)
              return (
                <React.Fragment key={pageRows[0]?.id}>
                  <tr
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <td
                      style={{
                        margin: '10px 0',
                        textAlign: 'left',
                        borderLeft: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        borderRight: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                      }}
                      colSpan={8}
                    >
                      <span style={{ fontSize: '1.34rem' }}>
                        {`Página ${currentPageNum} ${totalPages ? `de ${totalPages}` : ''}`}

                        {currentPageHasProblems && (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              width: '100%',
                              margin: '5px 0',
                            }}
                          >
                            <Alert severity="error" style={{ width: '100%' }}>
                              Houve um erro na extração de dados dessa página. Um ou mais itens
                              podem estar inválidos, repetidos ou faltando.
                            </Alert>
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                  {_.map(pageRows, (row) => (
                    <tr key={row?.id}>
                      {Object.entries(_.omit(row, ['id', 'page'])).map(([k, v], index) => (
                        <td key={k} style={createRowStyle(row, index, currentPageHasProblems)}>
                          {sectionColumns[k].type === 'currency' ? formatToCurrency(v) : v}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const renderSection = () => {
    if (_.isEmpty(incomeData)) {
      return null
    }

    return (
      <div className="values-files-container">
        <Button
          startIcon={showIncomeItems ? <ArrowDropDown /> : <ArrowRight />}
          onClick={() => setShowIncomeItems(!showIncomeItems)}
        >
          <strong style={{ fontSize: '1.34rem' }}>
            {showIncomeItems ? 'Esconder itens' : 'Exibir itens'}
          </strong>
        </Button>
        {showIncomeItems && (
          <div className="values-file-container">
            <div className="text-container">
              {/* <p>
                <span>Todos os itens estão corretos:</span>
                {' '}
                <span
                  className="file-correct"
                  style={{
                    color: _.isEmpty(incomeData.pages_with_problems) ? null : 'rgb(255, 23, 68)',
                  }}
                >
                  {booleanToText(_.isEmpty(incomeData.pages_with_problems))}
                </span>
              </p> */}
            </div>
            <div key={incomeData.name}>
              {renderSectionItems()}

              <Divider />
              <br />
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <Container>
      <h2>{sectionsInfo[sectionId]?.name}</h2>
      {_.isEmpty(incomeData) ? (
        <NoDataFoundMessage />
      ) : (
        <div>
          {renderTotals()}
          {renderSection()}
        </div>
      )}
    </Container>
  )
}

export default SingleTableIncome
