import styled from 'styled-components'

export default styled.div`
  width: 100%;

  .values-data-container {
    font-size: 1.7rem;

    .total-correct {
      font-weight: bold;
    }
  }

  .values-files-container {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #dddddd;
    width: 100%;

    .values-file-container {
      padding: 10px 0;
      border-top: 1px solid #eeeeee;

      .text-container {
        font-size: 1.5rem;

        .file-name, .file-correct {
          font-weight: bold;
        }
      }

      .table-container {
        margin-top: 10px;
        margin-bottom: 10px;

        table {
          border-collapse: collapse;
          width: 100%;
        }

        td, th {
          border: 1px solid #eeeeee;
          text-align: right;
          padding: 6px;
        }

        tr:nth-child(even) {
          background-color: #eeeeee;
        }
      }
    }

  }
`
