const styles = {
  tableContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
    table: {
      borderCollapse: 'collapse',
      width: '100%',

    '& td, & th': {
      border: '1px solid #eeeeee',
      textAlign: 'right',
      padding: '6px',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#eeeeee',
    },
  },
}

export default styles
