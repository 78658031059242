import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Button, Checkbox, CircularProgress } from '@material-ui/core'
import { booleanToText, formatToCurrency } from 'utils/functions'

import { Alert } from '@material-ui/lab'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import Container from '../styles'
import NoDataFoundMessage from '../../NoDataFoundMessage'
import { Colors } from 'utils/constants'

const AssetsDeclaration = ({ irDeclaration, onDeclarationCheck, readOnly }) => {
  const [showAssetsList, setShowAssetsList] = useState(true)
  const [itemLoading, setItemLoading] = useState(null)

  const handleClickDeclaration = (e,  id) => {
    setItemLoading(id)
    onDeclarationCheck(e, id)
  }

  useEffect(() => {
    setItemLoading(null)
  }, [irDeclaration])


  const renderTotals = () => {
    return (
      <div className="values-data-container">
        {irDeclaration?.assets_declaration?.last_year_total_value != null && (
          <p style={{ marginBottom: 10 }}>
            <span>Valor Total (Ano Anterior):</span>
            {' '}
            <span className="total-correct">
              {formatToCurrency(irDeclaration.assets_declaration.last_year_total_value)}
            </span>
          </p>
        )}
        {irDeclaration.total_value !== null && (
          <p>
            <span>Valor Total:</span>
            {' '}
            <span className="total-correct">{formatToCurrency(irDeclaration.total_value)}</span>
          </p>
        )}
        {irDeclaration.total_liquid_value !== null && (
          <p>
            <span>Valor Líquido Total:</span>
            {' '}
            <span className="total-correct">{formatToCurrency(irDeclaration.total_liquid_value)}</span>
          </p>
        )}
        {irDeclaration.total_liquid_value_80_percent !== null && (
          <p>
            <span>Valor Líquido Total (80%):</span>
            {' '}
            <span className="total-correct">
              {formatToCurrency(irDeclaration.total_liquid_value_80_percent)}
            </span>
          </p>
        )}
        {irDeclaration.equity_evolution !== null && (
          <p style={{ marginTop: 10 }}>
            <span>Evolução Patrimonial:</span>
            {' '}
            <span className="total-correct">{formatToCurrency(irDeclaration.equity_evolution)}</span>
          </p>
        )}
        {irDeclaration.valid_total !== null && (
          <p>
            <span>Valores Corretos:</span>
            {' '}
            <span className="total-correct">{booleanToText(irDeclaration.valid_total)}</span>
          </p>
        )}
      </div>
    )
  }

  const renderAssetItems = (assetsData, totalPages) => {
    const assetsItems = assetsData.items
    if (_.isEmpty(assetsItems)) return null

    const pages = _.groupBy(assetsItems, 'page')

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Calcular</th>
              {assetsItems[0]?.asset_group_code && <th>Grupo</th>}
              <th>Código</th>
              <th>Código do País</th>
              <th>Descrição</th>
              <th>Classificação</th>
              <th>Valor Ano Anterior</th>
              <th>Valor Atual</th>
            </tr>
          </thead>
          <tbody>
            {_.map(pages, (pageItems) => {
              const currentPageNum = pageItems[0]?.page
              const currentPageHasProblems = assetsData.pages_with_problems.includes(currentPageNum)
              return (
                <React.Fragment key={pageItems[0]?.id}>
                  <tr
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <td
                      style={{
                        margin: '10px 0',
                        textAlign: 'left',
                        borderLeft: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        borderRight: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                      }}
                      colSpan={8}
                    >
                      <span style={{ fontSize: '1.34rem' }}>
                        {`Página ${currentPageNum} ${totalPages ? `de ${totalPages}` : ''}`}

                        {currentPageHasProblems && (
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              width: '100%',
                              margin: '5px 0',
                            }}
                          >
                            <Alert severity="error" style={{ width: '100%' }}>
                              Houve um erro na extração de dados dessa página. Um ou mais itens
                              podem estar inválidos, repetidos ou faltando.
                            </Alert>
                          </span>
                        )}
                      </span>
                    </td>
                  </tr>
                  {_.map(pageItems, (item) => (
                    <tr key={item?.id}>
                      <td
                        style={{
                          textAlign: 'center', borderLeft: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        }}
                      >
                        {itemLoading === item.id ?  <CircularProgress size={20} /> :
                        <Checkbox
                          checked={item.checked}
                          color="primary"
                          disabled={readOnly || itemLoading}
                          onChange={(e) => handleClickDeclaration(e, item.id)}
                        />
                           }
                      </td>
                      {item.asset_group_code && <td>{item.asset_group_code}</td>}
                      <td>{item.asset_code}</td>
                      <td>{`${item.country_code} - ${item.country_name}`}</td>
                      <td>{item.asset_description}</td>
                      <td>{item.classification}</td>
                      <td>{formatToCurrency(item.before_year_asset_value)}</td>
                      <td
                        style={{
                          borderRight: currentPageHasProblems ? `1px solid ${Colors.red}` : null,
                        }}
                      >
                        {formatToCurrency(item.current_year_asset_value)}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const renderAssetsDeclaration = () => {
    const assetsData = irDeclaration?.assets_declaration
    const totalPages = irDeclaration?.total_pages

    if (_.isEmpty(assetsData?.items)) {
      return null
    }

    return (
      <div className="values-files-container">
        <Button
          startIcon={showAssetsList ? <ArrowDropDown /> : <ArrowRight />}
          onClick={() => setShowAssetsList(!showAssetsList)}
        >
          <strong style={{ fontSize: '1.34rem' }}>
            {showAssetsList ? 'Esconder itens' : 'Exibir itens'}
          </strong>
        </Button>
        {showAssetsList && (
          <div className="values-file-container">
            <div className="text-container">
              <p>
                <span>Todos os itens estão corretos:</span>
                {' '}
                <span
                  className="file-correct"
                  style={{
                    color: _.isEmpty(assetsData.pages_with_problems) ? null : 'rgb(255, 23, 68)',
                  }}
                >
                  {booleanToText(_.isEmpty(assetsData.pages_with_problems))}
                </span>
              </p>
            </div>

            {renderAssetItems(assetsData, totalPages)}
          </div>
        )}
      </div>
    )
  }

  return (
    <Container>
      <h2>Declaração de Bens e Direitos</h2>
      { !irDeclaration?.assets_declaration ? <NoDataFoundMessage />  : (
        <div>
          {renderTotals()}
          {renderAssetsDeclaration()}
        </div>
    )}
    </Container>
  )
}

export default AssetsDeclaration
