export const sectionsInfo = {
  income_from_legal_person_to_holder: {
    name: 'Rendimentos Tributáveis Recebidos de Pessoa Jurídica pelo Titular',
    columns: {
      payer_name: { name: 'NOME DA FONTE PAGADORA', type: 'name'  },
      income_from_legal_person: { name: 'REND. RECEBIDOS DE PES. JURÍDICA', type: 'currency', total_value_column: 'Total de Rend. Recebidos de Pes. Jurídica' },
      official_social_security_contribution: { name: 'CONTR. PREVID. OFICIAL', type: 'currency', total_value_column: 'Total de Contr. Previd. Oficial' },
      tax_withheld_at_source: { name: 'IMPOSTO RETIDO NA FONTE', type: 'currency', total_value_column: 'Total em Imposto Retido na Fonte' },
      thirteenth_salary: { name: '13º SALÁRIO', type: 'currency', total_value_column: 'Total em 13º Salário' },
      irpf_on_thirteenth_salary: { name: 'IRRF SOBRE 13º SALÁRIO', type: 'currency', total_value_column: 'Total em IRRF Sobre 13º Salário' },
      cpf_cnpj: { name: 'CNPJ/CPF', type: 'cpf_cnpj' },
    },
  },
  income_from_legal_person_to_dependents: {
    name: 'Rendimentos Tributáveis Recebidos de Pessoas Jurídicas pelos Dependentes',
    columns: {
      payer_name: { name: 'NOME DA FONTE PAGADORA', type: 'name' },
      income_from_legal_person: { name: 'REND. RECEBIDOS DE PES. JURÍDICA', type: 'currency', total_value_column: 'Total de Rend. Recebidos de Pes. Jurídica' },
      official_social_security_contribution: { name: 'CONTR. PREVID. OFICIAL', type: 'currency', total_value_column: 'Total de Contr. Previd. Oficial' },
      tax_withheld_at_source: { name: 'IMPOSTO RETIDO NA FONTE', type: 'currency', total_value_column: 'Total em Imposto Retido na Fonte' },
      thirteenth_salary: { name: '13º SALÁRIO', type: 'currency', total_value_column: 'Total em 13º Salário' },
      irpf_on_thirteenth_salary: { name: 'IRRF SOBRE 13º SALÁRIO', type: 'currency', total_value_column: 'Total em IRRF Sobre 13º Salário' },
      cpf_cnpj: { name: 'CNPJ/CPF', type: 'cpf_cnpj' },
      dependent_cpf: { name: 'CPF DO DEPENDENTE', type: 'cpf' },
    },
  },
}
